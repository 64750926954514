import React from "react";

export const Portfolio: React.FC = () => {
  return (
    <div className="page-what">
      <h1>Что делаю, чем занимаюсь</h1>
      <h2>Проекты продолжительностью от года и более</h2>
      {
        // gisp.gov.ru // VW // FCS // FIS Retail //
      }
      <ul>
        <li>
          Банковский сектор:
          <ul>
            <li>Стартап - закрыт - Микрозаймы онлайн</li>
            <li>
              Стартап - Площадка продажи долговых портфелей - debtplatform.ru
            </li>
          </ul>
        </li>
        <li>
          Социальные сети:
          <ul>
            <li>
              SocScor - проект закрыт - поиск и сбор информации из соц.сетей о
              заёмщике
            </li>
          </ul>
        </li>
        <li>
          Сервисы
          <ul>
            <li>
              med-room.com - проект закрыт - портал медицинских услуг по
              клиникам и врачам Новосибирска, Краснодара, Барнаула, Омска и др.
            </li>
            <li>Стартап - okkama - не доступен публично</li>
            <li>
              Стартап - protoplan.pro - сервис организации подготовки выставок
            </li>
          </ul>
        </li>
      </ul>
      <h3>Публичные, действующие проекты</h3>
      <ul>
        <li>lot-of-travels.ru</li>
        <li>visitchita.ru</li>
        <li>mbchita.ru</li>
      </ul>
      <h3>Боты в телеграмм</h3>
      @botFitFather @botka4ka4 @botfitonyashka
      <h3>GitHub</h3>
      <ul>
        <li>https://github.com/Rendol</li>
        <li>https://github.com/private-it</li>
      </ul>
    </div>
  );
};
