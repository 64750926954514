import React from "react";
import "./styles/App.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { About } from "./pages/About";
import { Experience } from "./pages/Experience";
import { Portfolio } from "./pages/Portfolio";

export const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <header className="head">
          <a href="https://sapegin.in/">
            <span className="head_title">Sapegin I.N.</span>
          </a>
          <span className="head_email">sapegin.in&#65312;gmail.com</span>
          <ul className="head_links">
            <li>
              <Link to={"/"}>Обо мне</Link>
            </li>
            <li>
              <Link to={"/experience"}>Мои знания</Link>
            </li>
          </ul>
        </header>
        <section className="content">
          <Switch>
            <Route path="/experience">
              <Experience />
            </Route>
            <Route path="/portfolio">
              <Portfolio />
            </Route>
            <Route path="/">
              <About />
            </Route>
          </Switch>
        </section>
      </div>
    </Router>
  );
};
