import React from "react";

export const About: React.FC = () => {
  return (
    <div className="page-about">
      <img
        className="avatar"
        src="https://avatars2.githubusercontent.com/u/2122240"
        alt={"avatar"}
      />
      <h1>Сапегин&nbsp;Игорь Николаевич</h1>

      <h2>Карьера</h2>
      <p>
        <b>2020г. – по настоящее время</b>
        <br />
        <a href="https://fisgroup.ru/" target="_blank" rel="noreferrer">
          ООО «Финансовые Информационные Системы»
        </a>
        <br />
        Руководитель web-разработки и mobile-разработки.
      </p>
      <p>
        <b>2019г. – 2020г.</b>
        <br />
        <a href="https://fisgroup.ru/" target="_blank" rel="noreferrer">
          ООО «Финансовые Информационные Системы»
        </a>
        <br />
        Руководитель web-разработки.
      </p>
      <p>
        <b>2012г. – 2019г.</b>
        <br />
        Организация удаленных команд разработки
      </p>
      <p>
        <b>2011г. – 2012г.</b>
        <br />
        <a
          href="https://web.archive.org/web/20120114043916/http://www.resalt.ru/"
          target="_blank"
          rel="noreferrer"
        >
          «ResAlt!»
        </a>
        <br />
        CTO
      </p>
      <p>
        <b>2009г. – 2011г.</b>
        <br />
        <a href="https://fisgroup.ru/" target="_blank" rel="noreferrer">
          ООО «Финансовые Информационные Системы»
        </a>
        <br />
        Web-разработчик
      </p>
      <p>
        <b>2009г.</b>
        <br />
        <a href="http://ucit.ru/" target="_blank" rel="noreferrer">
          УЦИТ НГТУ
        </a>
        <br />
        Преподаватель вечерних крусов по web-программированию (основы, курсы
        повышение квалификации).
      </p>
      <p>
        <b>2008г. – 2009г.</b>
        <br />
        <a
          href="https://web.archive.org/web/20131231204622/http://infoterra.ru/"
          target="_blank"
          rel="noreferrer"
        >
          ООО «Инфотерра»
        </a>
        <br />
        Web-разработчик
      </p>

      <h2>Текущее местоположение</h2>
      <p>Россия, Новосибирская область, г. Новосибирск.</p>

      <h2>Образование</h2>
      <p>
        <b>
          <a href="https://www.nstu.ru/" target="_blank" rel="noreferrer">
            Новосибирский Государственный Технический Университет (НГТУ)
          </a>
        </b>
        <br />
        Факультет радиотехники и электроники (РЭФ)
        <br />
        Направление «Электроника и микроэлектроника»
        <br />
        Специальность «Микроэлектроника и твердотельная электроника»
      </p>
    </div>
  );
};
