import React from "react";

export const Experience: React.FC = () => {
  return (
    <div className="page-what">
      <h1>Мои знания и опыт</h1>
      <h2>Программирование</h2>
      <h3>php5, php7. frameworks:</h3>
      Symfony, Yii, Yii2, Laravel, OrchidPlatform
      <h4>Инструменты</h4>
      <ul>
        <li>composer</li>
      </ul>
      <h3>Javascript</h3>
      Ванильный JS, TypeScript, nodejs, ExtJS, Vue, React, React-Native,
      AngularJS 5, RiotJS, jQuery
      <h4>NodeJS:</h4>
      <ul>
        <li>express</li>
        <li>loopback</li>
        <li>sequilize</li>
        <li>typeorm</li>
      </ul>
      <h4>Инструменты</h4>
      <ul>
        <li>npm</li>
        <li>webpack (настройка и использование HMR)</li>
      </ul>
      <h4>Дополнительно</h4>
      AngularJS v.1- поддержка проекта b2b.onetwotrip.com
      <h3>Форматы данных</h3>
      <ul>
        <li>xml</li>
        <li>json</li>
        <li>proto</li>
        <li>custom</li>
      </ul>
      <h3>Технологии</h3>
      ajax, xslt, RESTful API, SOAP (WSDL), web-сокеты
      <h3>Обработка документов</h3>
      <ul>
        <li>docx - https://github.com/private-it/PHPStamp</li>
        <li>xlsx - https://github.com/private-it/PHP_XLSXWriter</li>
      </ul>
      <h3>Облачные хранилища</h3>
      Flysystem: GoogleDrive, YandexDrive
      <h3>Социальные сети</h3>
      <ul>
        <li>VKontakte API</li>
        <li>Facebook API</li>
        <li>Odnoklassniki API</li>
        <li>Twitter API</li>
        <li>Telegram API</li>
      </ul>
      <h3>Прочий опыт в программировании</h3>
      <h4>Java</h4>
      Адаптер для подписи запросов в сервис{" "}
      <a href="https://www.equifax.com/" target="_blank" rel="noreferrer">
        Equifax
      </a>
      <br />
      Поддержка и доработка внутреннего продукта ФИС Gateway для VW (
      <a href="https://online.vwfs.ru/" target="_blank" rel="noreferrer">
        online.vwfs.ru
      </a>
      )<h4>C++</h4>
      <ul>
        <li>Расширение для PHP5</li>
        <li>Университетские лабораторные работы.</li>
      </ul>
      <h4>Python</h4>
      Опыт чтения чужого исходного кода для анализа его алгоритма работы.
      <br />
      <ul>
        <li>для написания фронтенда личного кабинета в виде SPA на Vue</li>
        <li>
          по анализу данных с GPS-трекера для поиска мест остановки транспорта
          для слива топлива
        </li>
      </ul>
      <hr />
      <h2>Вёрстка</h2>
      <ul>
        <li>html</li>
        <li>css3, scss</li>
      </ul>
      <h3>Frameworks</h3>
      bootstrap, material ui, AntD
      <h3>Инструменты</h3>
      <ul>
        <li>bower</li>
        <li>webpack</li>
      </ul>
      <hr />
      <h2>SEO</h2>
      <ul>
        <li>Основы SEO оптимизации технической части сайтов.</li>
        <li>Микроразметка (Schema.org)</li>
        <li>
          <img
            src="https://avatars.mds.yandex.net/get-bunker/120922/bf3489add015799336b81eb2c4cc81c27f7ad7d1/orig"
            alt={"Турбо-страницы"}
          />
          <a
            href="https://yandex.ru/adv/turbo"
            target="_blank"
            rel="noreferrer"
          >
            Турбо-страницы
          </a>
        </li>
      </ul>
      <hr />
      <h2>Базы данных</h2>
      PostgreSQL, Oracle, Mysql, MongoDB, Redis, SQLLite
      <hr />
      <h2>Поисковые движки</h2>
      <ul>
        <li>Sphinx</li>
        <li>ElasticSearch</li>
      </ul>
      <hr />
      <h2>Проектирование</h2>
      <ul>
        <li>Построение ERD для проектов</li>
        <li>Работа с монолитными приложениями</li>
        <li>
          Использование микросервисной архитектуры // telegram-боты // гисп
          сервисы (
          <a href="https://gisp.gov.ru/" target="_blank" rel="noreferrer">
            gisp.gov.ru
          </a>
          )
        </li>
      </ul>
      <hr />
      <h2>Инфраструктура</h2>
      <ul>
        <li>gitlab</li>
        <li>docker</li>
        <li>pm2</li>
        <li>nginx</li>
        <li>certbot (Letsencrypt)</li>
      </ul>
      <hr />
      <h2>Сервисы</h2>
      <ul>
        <li>PhantomJS - эмуляция работы с сайтом через браузер.</li>
        <li>CryptoPRO - электронная подпись: подписание, проверка</li>
        <li>EquifaxBKI - Бюро кредитных историй (БКИ)</li>
        <li>CyberPlat - скоринг для МФО</li>
      </ul>
      <hr />
      <h2>Тестирование</h2>
      Selenium, Selenium Web-driver
      <hr />
      <h2>Ведение задач</h2>
      Bugzilla, Redmine, Jira
      <hr />
      <h2>Системы контроля версий</h2>
      Git, Mercurial, SVN
      <h3>git-flow</h3>
      <hr />
      <h2>Операционные системы</h2>
      <h3>Домашняя ОС</h3>
      <s>Mint LMDE</s> MacOS
      <h3>Серверные ОС</h3>
      Debian, CentOS, Windows Server
      <hr />
      <h2>Среды разработки</h2>
      <h3>Работал</h3>
      ZendStudio, Eclipse, NetBeans
      <h3>Сейчас работаю</h3>
      WebStorm, PhpStorm, PyCharm
    </div>
  );
};
